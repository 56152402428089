<template>
    <section v-if="item.externalFrame != undefined" :id="'frame-' + item.guidKeyChat" >
        <div class="externalFrameContainer">
            <iframe v-if="item.externalFrame.url != undefined" class="externalFrameBot" :title="item.externalFrame.title" 
                v-bind:src="item.externalFrame.url" 
                sandbox="allow-storage-access-by-user-activation
                 allow-scripts
                 allow-same-origin"></iframe>
            <div v-if="item.externalFrame.url != undefined" class="externalFrameMask"></div>
            <input type="hidden" :id="'fromUseCase-' + item.guidKeyChat" class="fromUseCase" :value="item.externalFrame.fromUseCase" />
        </div>
    </section>
</template>

<script>
    /* eslint-disable */
    import Vue from "vue";
    import moment from 'moment';

    export default Vue.component("ExternalFrameContainer", {
        data(){
            return {
                coordenadas: ''
            }
        },
        mounted() {
            this.setPermission();
        },
        props: [
        "item"
        ],
        methods: {
            setPermission() {
                let userAgent = navigator.userAgent.toLowerCase();
                let isSafari = userAgent.indexOf("safari") !== -1 && userAgent.indexOf("chrome") === -1;

                console.log('is safari:' + isSafari);

                if (isSafari) {
                    document.requestStorageAccess({ localStorage: true }).then(
  (handle) => {
    console.log("localStorage access granted");
    handle.localStorage.setItem("foo", "bar");
  },
  () => {
    console.log("localStorage access denied");
  },
);
                }
            }
        }
    })
</script>